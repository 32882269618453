import React from 'react'
import classnames from 'classnames'

import { formatDayMonthYear, formatISO, formatISOMonth, isBefore, today } from './lib/date'
import { CompactEmployeeBaseDifferenceAdjustment, HoursContractSeason } from './lib/domain'
import { Locale } from './lib/reducers/settings'

import styles from './css/Header.module.css'
import { useDispatch } from 'react-redux'

import { useAppSelector } from './lib/hooks'
import { format } from 'date-fns'

const shouldShowHoursDifferenceForContractType = (contractType: string) =>
  !['not_required', 'subcontractor'].includes(contractType)
const shouldShowFlextimeWarningForContract = (contract: HoursContractSeason) => contract.company.country === 'FI'
const doesContractTypeHaveFlextime = (contractType: string) => {
  switch (contractType) {
    case 'hourly':
    case 'subcontractor':
    case 'not_required':
      return false

    default:
      return true
  }
}
const isFlextimeOverNormalRange = (flexiTimeAmount: number) => Math.abs(flexiTimeAmount) > 40

export interface HeaderProps {
  loggedInUsername: string
  dataOwnerUsername?: string
  userFullName: string
  locale: Locale
  totalHoursDifference: number
  toggleMenu: () => void
  hasUnsentData: boolean
  isPostingData: boolean
  didLastServerUpdateFail: boolean
  daysWithErrors: Date[]
  contract?: HoursContractSeason
  earliestUnlockedMonth?: string
  differenceAdjustment: CompactEmployeeBaseDifferenceAdjustment | undefined
}

const Header: React.FC<HeaderProps> = ({
  loggedInUsername,
  dataOwnerUsername,
  userFullName,
  locale,
  totalHoursDifference,
  toggleMenu,
  hasUnsentData,
  isPostingData,
  didLastServerUpdateFail,
  daysWithErrors,
  contract,
  earliestUnlockedMonth,
  differenceAdjustment,
}) => {
  const dispatch = useDispatch()
  const isImpersonating = loggedInUsername !== dataOwnerUsername
  const showHoursDifference = contract && shouldShowHoursDifferenceForContractType(contract.type)
  const hasTooMuchFlexTime =
    showHoursDifference &&
    contract &&
    shouldShowFlextimeWarningForContract(contract) &&
    doesContractTypeHaveFlextime(contract.type) &&
    isFlextimeOverNormalRange(totalHoursDifference)

  let headerMessage = ''
  let headerMessageIsError = false

  if (isImpersonating) {
    headerMessage = locale.texts.impersonatingAs.replace('$', `${userFullName || ''} (${dataOwnerUsername})`)
  } else if (earliestUnlockedMonth && formatISOMonth(today()) !== earliestUnlockedMonth) {
    const monthName = format(earliestUnlockedMonth, 'MMMM')
    headerMessage = locale.texts.monthUnlockedError(monthName)
    headerMessageIsError = true
  } else if (hasTooMuchFlexTime) {
    headerMessage = locale.texts.flexitimeOverNormalRange
    headerMessageIsError = true
  }

  const employeeContractFinished = contract && contract.finish && isBefore(contract.finish, today())

  const hoursDifferencePrefix =
    totalHoursDifference > 0 && contract && doesContractTypeHaveFlextime(contract.type) ? '+' : ''
  const formattedFlextime =
    showHoursDifference &&
    !isNaN(totalHoursDifference) &&
    `${hoursDifferencePrefix}${totalHoursDifference} h` +
      (employeeContractFinished ? ` (${formatDayMonthYear(locale)(contract.finish)})` : '')

  const currentlyEditingDay = useAppSelector((state) => state.data.personalHoursClient.currentlyEditingDay)
  const hasValidationErrors = daysWithErrors.filter((day) => formatISO(day) !== currentlyEditingDay).length > 0

  const autosaveMessage = () => {
    const { unsentChanges, postingChanges, saveFail, saveSuccessful } = locale.texts.autosave

    let msg: string

    if (hasUnsentData) msg = unsentChanges
    else if (isPostingData) msg = postingChanges
    else if (didLastServerUpdateFail) msg = saveFail
    else msg = saveSuccessful

    return <>{msg}</>
  }

  const scrollToFirstError = () => {
    dispatch({
      type: 'CHANGE_CURRENTLY_EDITING_DAY',
      day: formatISO(daysWithErrors[0]),
      rescrollNonce: Date.now(),
    })
  }

  const validationErrors = (layout: 'mobile' | 'desktop') =>
    hasValidationErrors ? (
      <div
        data-test='show-errors'
        className={classnames(
          styles.errorLink,
          styles.red,
          layout === 'mobile' ? styles.mobileOnly : styles.desktopOnly
        )}
        onClick={scrollToFirstError}
      >
        <span>{locale.texts.showErrors}</span>
      </div>
    ) : null

  const differenceAdjustmentText = () => {
    if (!showHoursDifference || isNaN(totalHoursDifference) || differenceAdjustment === undefined) return null
    const { day: adjustmentDay, note: adjustmentNote, hoursAdjustment } = differenceAdjustment
    const prefix = hoursAdjustment > 0 ? '+' : ''
    return (
      <div data-test='difference-adjustment' className={classnames(styles.differenceAdjustment)}>
        <span className={styles.differenceAdjustmentHours}>{`${prefix}${hoursAdjustment} h (`}</span>
        <span className={styles.differenceAdjustmentNote}>{`${adjustmentNote} `}</span>
        <span className={styles.differenceAdjustmentDay}>{`${formatDayMonthYear(locale)(adjustmentDay)}`}</span>
        {')'}
      </div>
    )
  }

  return (
    <div className={styles.header}>
      <div
        className={classnames(styles.autoSaveContainer, {
          /* eslint-disable @typescript-eslint/no-non-null-assertion */
          [styles.visible!]: hasUnsentData || isPostingData,
          [styles.longTransition!]: didLastServerUpdateFail,
          [styles.waiting!]: hasUnsentData || isPostingData,
          [styles.success!]: !hasUnsentData && !isPostingData && !didLastServerUpdateFail,
          [styles.failure!]: !hasUnsentData && !isPostingData && didLastServerUpdateFail,
          /* eslint-enable @typescript-eslint/no-non-null-assertion */
        })}
      >
        {autosaveMessage()}
      </div>
      <a href={window.location.origin}>
        <img className={styles.logo} alt='Hours logo' src='/reaktor_r.svg' />
      </a>
      <div className={styles.headerContent}>
        <span className={classnames(headerMessageIsError && styles.red, styles.centered)} data-test='header-message'>
          {headerMessage}
        </span>
        <div className={styles.headerStatusInfo}>
          <div className={styles.timeEntryStatusContainer}>
            <div
              data-test='time-entry-status'
              className={classnames(
                hasTooMuchFlexTime && styles.red,
                styles.timeEntryStatus,
                !hasValidationErrors && styles.centered
              )}
            >
              {formattedFlextime}
            </div>
            {differenceAdjustmentText()}
          </div>
          {validationErrors('mobile')}
        </div>
      </div>

      <div className={classnames(styles.headerSidebar, styles.desktopOnly)}>{validationErrors('desktop')}</div>
      <div
        data-test='header-dots'
        onClick={toggleMenu}
        className={styles.profilePicture}
        style={{
          backgroundImage: `url(https://cdn.rex.reaktor.cloud/people/400x500/${encodeURIComponent(
            dataOwnerUsername ?? loggedInUsername
          )}.jpg)`,
        }}
      ></div>
    </div>
  )
}
export default Header
