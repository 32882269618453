import { Action, Reducer } from 'redux'
import _ from 'lodash'
import { today } from '../date'
import {
  EmployeeHolidayStatus,
  ExpectedDailyHours,
  FloatingHolidaySummary,
  HourCodePolicy,
  HoursContractSeason,
  InvoiceTaskInfo,
  QuotaLimitedAbsenceSummary,
  UnpaidVacationSummary,
  WorkDay,
  WorkEntry,
  WorkMonth,
  EditContext,
  AbsenceCode,
  CompactEmployeeBaseDifferenceAdjustment,
} from '../domain'
import { createDefaultDay } from '../util'
import { clearEntryValidations } from '../validation'
import { PayloadAction } from './payload-action'
import { UpdateDataOwnerAction } from './user'

const entryDefaults = { hours: '', hourCode: '', note: '', startTime: '', endTime: '' }

const omittableEntryFields = ['validation', 'id']

type UpdateExpectedHoursAction = PayloadAction<
  'UPDATE_EXPECTED_HOURS',
  { expectedHours: PersonalHoursClientState['userExpectedHours'] }
>
type UpdateContractsAction = PayloadAction<'UPDATE_CONTRACTS', { contracts: PersonalHoursClientState['contracts'] }>
type UpdateProjectCodesWithTimeRangesAction = PayloadAction<
  'UPDATE_PROJECT_CODES_WITH_TIME_RANGES',
  { hourCodes: PersonalHoursClientState['hourCodesWithTimeRanges'] }
>
type UpdatePublicHolidaysAction = PayloadAction<
  'UPDATE_PUBLIC_HOLIDAYS',
  { publicHolidays: PersonalHoursClientState['publicHolidays'] }
>
type UpdateUnpaidVacationSummaryAction = PayloadAction<
  'UPDATE_UNPAID_VACATION_SUMMARY',
  { unpaidVacationSummary: PersonalHoursClientState['unpaidVacationSummary'] }
>
type UpdateFloatingHolidaySummaryAction = PayloadAction<
  'UPDATE_FLOATING_HOLIDAY_SUMMARY',
  { floatingHolidaySummary: PersonalHoursClientState['floatingHolidaySummary'] }
>
type UpdateQuotaLimitedAbsenceSummaryAction = PayloadAction<
  'UPDATE_QUOTA_LIMITED_ABSENCE_SUMMARY',
  { quotaLimitedAbsenceSummary: PersonalHoursClientState['quotaLimitedAbsenceSummary'] }
>
type UpdateCurrentHolidaysAction = PayloadAction<
  'UPDATE_CURRENT_HOLIDAYS',
  { currentHolidays: PersonalHoursClientState['currentHolidays'] }
>
type UpdateServerDaysAction = PayloadAction<
  'UPDATE_SERVER_DAYS',
  {
    days: PersonalHoursClientState['serverDays']
    months: PersonalHoursClientState['serverMonths']
    versionConflictState: PersonalHoursClientState['versionConflictState']
  }
>
type UpdateServerDaysPartiallyAction = PayloadAction<
  'UPDATE_SERVER_DAYS_PARTIALLY',
  {
    updatedDays: PersonalHoursClientState['serverDays']
    updatedMonthVersions: PersonalHoursClientState['versionConflictState']
  }
>
type UpdateServerMonthAction = PayloadAction<
  'UPDATE_SERVER_MONTH',
  {
    workMonth: Pick<WorkMonth, 'month' | 'locked'>
  }
>
type UpdateLocalEntryAction = PayloadAction<
  'UPDATE_LOCAL_ENTRY',
  { editContext: EditContext; updateDiff: Partial<WorkEntry> }
>
type UpdateAbsenceCodesAction = PayloadAction<'UPDATE_ABSENCE_CODES', { absenceCodes: AbsenceCode[] }>
type BeginServerSendAction = Action<'BEGIN_SERVER_SEND'>
type ServerSendCompletedAction = PayloadAction<
  'SERVER_SEND_COMPLETED',
  {
    updatedDays: PersonalHoursClientState['serverDays']
    totalHoursDifference: PersonalHoursClientState['totalHoursDifference']
    versionConflictState: PersonalHoursClientState['versionConflictState']
  }
>
type ServerSendFailedAction = Action<'SERVER_SEND_FAILED'>
type ChangeCurrentlyEditingDayAction = PayloadAction<
  'CHANGE_CURRENTLY_EDITING_DAY',
  {
    day: PersonalHoursClientState['currentlyEditingDay']
    rescrollNonce?: number
  }
>
type UpdateConnectionStatusAction = PayloadAction<
  'UPDATE_CONNECTION_STATUS',
  {
    isServerConnectionWorking: PersonalHoursClientState['isServerConnectionWorking']
  }
>

type UpdateEmployeeBaseDifferenceAdjustments = PayloadAction<
  'UPDATE_EMPLOYEE_BASE_DIFFERENCE_ADJUSTMENTS',
  { baseDifferenceAdjustments: PersonalHoursClientState['baseDifferenceAdjustments'] }
>

type UpdateHourCodePolicyAction = PayloadAction<'UPDATE_POLICY_GROUPS', { hourCodePolicies: HourCodePolicy[] }>
type PersonalHoursClientAction =
  | UpdateExpectedHoursAction
  | UpdateContractsAction
  | UpdateProjectCodesWithTimeRangesAction
  | UpdatePublicHolidaysAction
  | UpdateUnpaidVacationSummaryAction
  | UpdateFloatingHolidaySummaryAction
  | UpdateQuotaLimitedAbsenceSummaryAction
  | UpdateCurrentHolidaysAction
  | UpdateServerDaysAction
  | UpdateServerDaysPartiallyAction
  | UpdateServerMonthAction
  | UpdateLocalEntryAction
  | BeginServerSendAction
  | ServerSendCompletedAction
  | ServerSendFailedAction
  | ChangeCurrentlyEditingDayAction
  | UpdateConnectionStatusAction
  | UpdateDataOwnerAction
  | UpdateHourCodePolicyAction
  | UpdateAbsenceCodesAction
  | UpdateEmployeeBaseDifferenceAdjustments

export interface PersonalHoursClientState {
  serverDays: Record<WorkDay['day'], WorkDay>
  serverMonths: Record<WorkMonth['month'], WorkMonth>
  userExpectedHours: Record<ExpectedDailyHours['day'], ExpectedDailyHours>
  totalHoursDifference: number
  sentChangedLocalDays: Record<WorkDay['day'], WorkDay>
  unsentChangedLocalDays: Record<WorkDay['day'], WorkDay>
  hourCodesWithTimeRanges: InvoiceTaskInfo[]
  contracts: HoursContractSeason[]
  publicHolidays: Record<string, string>
  versionConflictState: { [day: string]: number }
  hasServerDaysBeenLoaded: boolean
  isPostingData: boolean
  didLastServerUpdateFail: boolean
  currentlyEditingDay: string
  isServerConnectionWorking: boolean
  currentHolidays?: EmployeeHolidayStatus
  unpaidVacationSummary?: UnpaidVacationSummary
  floatingHolidaySummary?: FloatingHolidaySummary
  quotaLimitedAbsenceSummary?: QuotaLimitedAbsenceSummary
  hourCodePolicies: { [policyGroup: string]: HourCodePolicy[] }
  rescrollNonce?: number
  absenceCodes: AbsenceCode[]
  baseDifferenceAdjustments: CompactEmployeeBaseDifferenceAdjustment[]
}

const DEFAUlT_PERSONAL_HOURS_CLIENT: PersonalHoursClientState = {
  serverDays: {},
  serverMonths: {},
  userExpectedHours: {},
  sentChangedLocalDays: {},
  unsentChangedLocalDays: {},
  isPostingData: false,
  didLastServerUpdateFail: false,
  contracts: [],
  hourCodesWithTimeRanges: [],
  publicHolidays: {},
  currentlyEditingDay: today(),
  isServerConnectionWorking: true,
  currentHolidays: undefined,
  totalHoursDifference: 0,
  versionConflictState: {},
  hasServerDaysBeenLoaded: false,
  hourCodePolicies: {},
  absenceCodes: [],
  baseDifferenceAdjustments: [],
}

export const personalHoursClient: Reducer<PersonalHoursClientState, PersonalHoursClientAction> = (
  state = DEFAUlT_PERSONAL_HOURS_CLIENT,
  action
) => {
  switch (action.type) {
    case 'UPDATE_DATA_OWNER_USER': {
      const { totalHoursDifference } = action.employee
      return { ...state, totalHoursDifference }
    }
    case 'UPDATE_EXPECTED_HOURS':
      return { ...state, userExpectedHours: action.expectedHours }
    case 'UPDATE_CONTRACTS':
      return { ...state, contracts: action.contracts }
    case 'UPDATE_PROJECT_CODES_WITH_TIME_RANGES':
      return { ...state, hourCodesWithTimeRanges: action.hourCodes }
    case 'UPDATE_PUBLIC_HOLIDAYS':
      return { ...state, publicHolidays: action.publicHolidays }
    case 'UPDATE_UNPAID_VACATION_SUMMARY':
      return { ...state, unpaidVacationSummary: action.unpaidVacationSummary }
    case 'UPDATE_FLOATING_HOLIDAY_SUMMARY':
      return { ...state, floatingHolidaySummary: action.floatingHolidaySummary }
    case 'UPDATE_QUOTA_LIMITED_ABSENCE_SUMMARY':
      return { ...state, quotaLimitedAbsenceSummary: action.quotaLimitedAbsenceSummary }
    case 'UPDATE_CURRENT_HOLIDAYS':
      return { ...state, currentHolidays: action.currentHolidays }
    case 'UPDATE_ABSENCE_CODES':
      return { ...state, absenceCodes: action.absenceCodes }
    case 'UPDATE_EMPLOYEE_BASE_DIFFERENCE_ADJUSTMENTS':
      return { ...state, baseDifferenceAdjustments: action.baseDifferenceAdjustments }
    case 'UPDATE_POLICY_GROUPS':
      return {
        ...state,
        hourCodePolicies: _.merge(state.hourCodePolicies, _.groupBy(action.hourCodePolicies, 'policyGroup')),
      }
    case 'UPDATE_SERVER_DAYS': {
      const { days, months, versionConflictState } = action
      return { ...state, serverDays: days, serverMonths: months, versionConflictState, hasServerDaysBeenLoaded: true }
    }
    case 'UPDATE_SERVER_DAYS_PARTIALLY': {
      const { updatedDays, updatedMonthVersions } = action
      return {
        ...state,
        serverDays: { ...state.serverDays, ...updatedDays },
        versionConflictState: { ...state.versionConflictState, ...updatedMonthVersions },
      }
    }
    case 'UPDATE_SERVER_MONTH': {
      const { month, locked } = action.workMonth
      const existingServerMonth = state.serverMonths[month]
      if (existingServerMonth) {
        return { ...state, serverMonths: { ...state.serverMonths, [month]: { ...existingServerMonth, locked } } }
      } else {
        return state
      }
    }
    case 'UPDATE_LOCAL_ENTRY': {
      const { editContext, updateDiff } = action
      const { day, indexInDay } = editContext

      const mergedDays = { ...state.serverDays, ...state.sentChangedLocalDays, ...state.unsentChangedLocalDays }
      const dayToBeUpdated: WorkDay = _.cloneDeep(mergedDays[day]) || createDefaultDay(day)
      // Discard potentially invalid server validation error for day
      dayToBeUpdated.validation = undefined
      const entryValidation = dayToBeUpdated.entries[indexInDay]?.validation
      // Remove validation errors for edited local entries
      const validation = entryValidation && clearEntryValidations(entryValidation, updateDiff)
      const newEntry = { ...entryDefaults, ...dayToBeUpdated.entries[indexInDay], ...updateDiff, validation }
      if (_.isEqual(_.omit(newEntry, ...omittableEntryFields), entryDefaults)) {
        dayToBeUpdated.entries.splice(indexInDay, 1)
      } else {
        dayToBeUpdated.entries[indexInDay] = newEntry
      }

      return { ...state, unsentChangedLocalDays: { ...state.unsentChangedLocalDays, [day]: dayToBeUpdated } }
    }
    case 'BEGIN_SERVER_SEND': {
      return {
        ...state,
        sentChangedLocalDays: state.unsentChangedLocalDays,
        unsentChangedLocalDays: {},
        isPostingData: true,
      }
    }
    case 'SERVER_SEND_COMPLETED': {
      const { updatedDays, totalHoursDifference, versionConflictState } = action
      return {
        ...state,
        serverDays: { ...state.serverDays, ...updatedDays },
        sentChangedLocalDays: {},
        isPostingData: false,
        didLastServerUpdateFail: false,
        totalHoursDifference,
        versionConflictState,
      }
    }
    case 'SERVER_SEND_FAILED': {
      return {
        ...state,
        sentChangedLocalDays: {},
        unsentChangedLocalDays: {},
        didLastServerUpdateFail: true,
        isPostingData: false,
      }
    }
    case 'CHANGE_CURRENTLY_EDITING_DAY': {
      return {
        ...state,
        currentlyEditingDay: action.day,
        rescrollNonce: action.rescrollNonce,
      }
    }
    case 'UPDATE_CONNECTION_STATUS': {
      return { ...state, isServerConnectionWorking: action.isServerConnectionWorking }
    }

    default:
      return state
  }
}
